import React from 'react';
import i18nWatcher from 'utils/i18nWatcher';
import i18n from 'i18next';
import AppContext from 'utils/AppContext'

class StoreSwitcher extends React.Component {

  onChange = (e) => {
    const { setStore } = this.context
    setStore(e.target.value);

    i18nWatcher.getAll().forEach(function(comp) {
      comp.setState({
        isLoaded: false
      }, () => comp.load());
    });
  }

  render() {    
    const { store, stores } = this.context;    
    const options = [];
    let count = 0;    
    for (const sto in stores) {
      const store_name = stores[sto].country.name[i18n.language];
      count += 1;      
      const props = {
        key: sto,
        value: sto,
        sort: store_name,
      };
      options.push(
        <option {...props}>
          {store_name} ({sto})
        </option>
      );      
    }    
    options.sort((a, b) => (a.props.sort.localeCompare(b.props.sort)));
    if (count < 2) {
      return null;
    }
    return (
      <select
        className="store-switcher pimp-my-select"
        onChange={this.onChange}
        defaultValue={store}>
        {options}
      </select>
    );
  }
}
StoreSwitcher.contextType = AppContext;

export default StoreSwitcher;
