import { TimeoutError } from './Errors';

function fetchWithTimeout(resource, options = {}) {
  return new Promise(function(resolve, reject) {
    const { timeout = 5000 } = options;
  
    const abortController = new AbortController();
    const id = setTimeout(() => {
      abortController.abort();
      reject(new TimeoutError());
    }, timeout);

    fetch(resource, {
      ...options,
      signal: abortController.signal  
    }).then(resp => {
      clearTimeout(id);
      resolve(resp);
    }).catch(err => {
      clearTimeout(id);
      reject(err);
    });
  });
}

export default fetchWithTimeout;