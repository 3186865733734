import React, { useContext } from 'react';
import AppContext from 'utils/AppContext';
import { Link } from 'react-router-dom'

function CollectionNavItem(props) {
  const { collection, close, nav } = props;
  const context = useContext(AppContext);
  if (collection.children.length > 0) {
    return (
      <div className="nav-collection--item">
        <button type="button" onClick={() => { nav(collection) }}>{collection.name}</button>
      </div>
    );
  }
  return (
    <div className="nav-collection--item">
      <Link
        to={'/collection/' + collection.id} 
        onClick={() => {
          close();
          context.resetFilters();
        }}>{collection.name}</Link>
    </div>
  );
}

export default CollectionNavItem;
