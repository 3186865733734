/* eslint camelcase: "off" */
import ProductList from 'models/ProductList';

class Products extends ProductList {
  constructor(store, token) {
    super('api/{store}/product/', store, token);
  }

}

export default Products;
