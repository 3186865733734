import React from 'react';
import { withTranslation } from 'react-i18next';

class TechnicalSpecs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'about_the_watch'
    };
  }

  setSelectedTab = (selectedTab) => {
    this.setState({
      selectedTab: selectedTab
    });
  };

  get tabs_attributes() {
    const { t } = this.props;
    return {
      about_the_watch: {
        title: t('About the watch'),
        attrs: [
          {
            id: 'sku',
            title: t('SKU'),
            value: (data) => data.sku
          },
          'origin',
          'warranty_tissot',
          'reference_size',
          'krimporter',
          'collection_label',
          {
            id: 'weight',
            title: t('Weight (g)'),
            value: (data) => 1000 * data.weight
          },
          'sousfamille_features_montre_etan_label',
        ]
      },
      case_and_crystal: {
        title: t('Case & crystal'),
        attrs: [
          'reference_shape',
          'reference_features_boite_label',
          'reference_diameter12h6h',
          'reference_diameter3h9h',
          'reference_lugs',
          'reference_box_height',
          'reference_case_options_label',
          'reference_diamond_case_diamond_quantity',
          'reference_diamond_case_diamond_colour',
          'reference_diamond_case_diamond_clarity',
          'reference_diamond_case_diamond_cut',
          'reference_diamond_case_diamond_carat',
          'sousfamille_features_glace_label',
        ]
      },
      movement: {
        title: t('Movement'),
        attrs: [
          'sousfamille_tissotmovementcaliber_movement_entitled',
          'reference_is_chronometer',
          'sousfamille_tissotmovementcaliber_mouvement_web',
          'sousfamille_tissotmovementcaliber_movementcaliber_caliber',
          'sousfamille_tissotmovementcaliber_movmentcaliber_diameter',
          'sousfamille_tissotmovementcaliber_jewelsmovement',
          'sousfamille_tissotmovementcaliber_tissotbattery',
          'sousfamille_tissotmovementcaliber_energy',
          'sousfamille_tissotmovementcaliber_movmentcaliber_dial',
          'sousfamille_tissotmovementcaliber_tissotfunctionssummary',
          'sousfamille_tissotmovementcaliber_tissotpowerreserve',
        ]
      },
      dial: {
        title: t('Dial'),
        attrs: [
          'reference_dial_color_label',
          'reference_dial_type_label',
          'reference_diamond_dial_diamond_quantity',
          'reference_diamond_dial_diamond_colour',
          'reference_diamond_dial_diamond_clarity',
          'reference_diamond_dial_diamond_cut',
          'reference_diamond_dial_diamond_carat',
        ]
      },
      strap: {
        title: t('Strap'),
        attrs: [
          'bracelet_code',
          'bracelet_colorisbracelet_label',
          'bracelet_brac_text_face',
          'bracelet_brac_text_back',
          'bracelet_brac_color_label',
          'reference_bracelet_options_label',
          'reference_diamond_brac_diamond_quantity',
          'reference_diamond_brac_diamond_colour',
          'reference_diamond_brac_diamond_clarity',
          'reference_diamond_brac_diamond_cut',
          'reference_diamond_brac_diamond_carat',
          'reference_braceletsxlcode',
          'reference_braceletsxscode',
          'bracelet_links_ref'
        ]
      }
    };
  }

  renderAttributes = (selectedTab, product) => (
    this.tabs_attributes[selectedTab].attrs.map((attr) => {
      const attribute = product.getAttribute(attr);
      if (attribute.value !== null) {        
        return (
          <div className="tabs-item" key={typeof attr === 'string' ? attr : attr.id}>
            <span className="label">{attribute.label}</span>
            {' '}
            <span className="value">{attribute.value}</span>
          </div>
        );
      }
      return null;
    }));

  render() {
    const { selectedTab } = this.state;
    const { product } = this.props;
    const tabs = [];
    for (const tab in this.tabs_attributes) {
      const props = {
        onClick: () => this.setSelectedTab(tab)
      };
      if (tab === selectedTab) props.className = 'active';
      tabs.push(
        <button type="button" key={tab} {...props}>
          {this.tabs_attributes[tab].title}
        </button>
      );
    }

    return (
      <div className="tabs">
        <div className="tabs-menu">
          {tabs}
        </div>
        <div className="tabs-content">
          {this.renderAttributes(selectedTab, product)}
        </div>
      </div>
    )
  }
}

export default withTranslation()(TechnicalSpecs);
