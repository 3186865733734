import React from 'react';
import CollectionNavItem from 'components/nav/CollectionNavItem';
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import AppContext from 'utils/AppContext';

class CollectionNavigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      categoryPath: [],
      categoryActive: this.props.model._hierarchy.id
    };

    this.onNav = this.onNav.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  onNav(collection, e) {
    this.setState(prevState => ({
      categoryPath: prevState.categoryPath.concat(collection.index),
      categoryActive: collection.id
    }));
    this.props.selectedMenu('collection')
  }

  onBack() {
    this.setState(function(prevState) {
      const path = prevState.categoryPath.slice(0, prevState.categoryPath.length - 1);
      const active = this.props.model.getList(path);
      return {
        categoryPath: path,
        categoryActive: active.id
      };
    }.bind(this), () => {
      if (this.state.categoryPath.length === 0) {
        this.props.selectedMenu(null)
      }
    });
  }

  render() {
    const { model, close, style, t } = this.props;
    const { categoryPath, categoryActive } = this.state;
    const nav = model.getList(categoryPath);
    return (
      <div className="nav-collection" style={style}>
        <div className="nav-collection--list">
          { this.state.categoryPath.length > 0 && (
            <>
              <div className="nav-collection--item parent">
                <button type="button" onClick={this.onBack}>{nav.name}</button>
              </div>
              <div className="nav-collection--item see-all">
                <Link
                  to={'/collection' + (categoryActive !== null ? '/' + categoryActive : '')}
                  onClick={() => {
                    close();
                    this.context.resetFilters();
                  }}>{t('See all')}</Link>
              </div>
            </>
          )}
          
          {nav.children.map(item => (
            <CollectionNavItem
              collection={item}
              nav={this.onNav}
              key={item.id}
              close={close} />
          ))}
        </div>
      </div>
    );
  }
}
CollectionNavigation.contextType = AppContext;

export default withTranslation()(CollectionNavigation);
