import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import month_utils from 'utils/month_utils';

function ProductNovelty(props) {
  const { t } = useTranslation();
  
  if (!props.product.is_novelties) {
    return null
  }
  return (
    <div className="novelty">
      <div className="badge novelty-badge">new</div>
      <div className="label">{t('Point of sales launch')}: {month_utils(new Date(props.product.launch_date), i18n.language)}</div>
    </div>
  );
}

export default ProductNovelty;
