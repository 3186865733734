import React from 'react';

function ProductImages(props) {
  const { product } = props;
  // eslint-disable-next-line prefer-const
  let { size, images } = product.getAllImages();

  const family_image = {}
  // TODO: manage fillers with family image or remove this
  // let family_image = {}
  // if (product.getFamilyBackImage() !== null) {
  //   family_image = {
  //     backgroundImage: 'url(' + product.getFamilyBackImage() + ')'
  //   }
  // }

  if (product.video) {
    size += 2;
  }
  const fill = [];
  const last = size % 4;
  if (last === 3) {
    fill.push(<div className="spacer" style={family_image} key="spacer"></div>);
  }
  else if (last === 2) {
    fill.push(<div className="spacer col-2" key="spacer2"></div>);
  }
  else if (last === 1) {
    fill.push(<div key="spacer3" className="spacer col-2"></div>);
    fill.push(<div className="spacer" style={family_image} key="spacer4"></div>);
  }

  return (
    <div className="images grid">
      {product.video && (
        <div className="watch-teaser video col-2" key="video">
          <iframe
            title="video"
            width="652"
            height="406"
            src={`https://www.youtube.com/embed/${product.video}?rel=0&modestbranding=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        </div>
      )}
      {images.map(img => (
        <div className={`watch-teaser ${img.name}${img.size === 2 ? ' col-2' : ''}`} key={img.name}>
          <div className="content">
            <div className="content-inside">
              <img className="img-soldier" src={img.url} alt="" />
            </div>
          </div>
        </div>
      ))}
      {fill}

    </div>
  );
}

export default ProductImages;
