import React from 'react';
import WatchTeaser from 'components/WatchTeaser';
import SeparatedTitle from 'components/SeparatedTitle';
import Watch from 'models/Watch';

function getFamilyImage(image_name) {
  if (image_name === null) {
    return '/images/categories/default.jpg';
  }
  return '/images/categories/' + image_name;
}

function fillers(last, family) {
  const fill = [];

  const watchList = [];
  for (let i = 0; i < family.items.length; i++) {
    watchList.push(new Watch(family.items[i]));
  }
  let imgs = watchList.filter(item => item.images.detail1 !== null);
  let type = 'detail1';
  if (imgs.length === 0) {
    imgs = watchList.filter(item => item.images.base !== null);
    type = 'base';
  }

  let noImage = false;
  const style = {};
  if (imgs.length === 0) {
    noImage = true;
  }
  else {
    const watch = imgs[Math.floor(Math.random() * imgs.length)];
    const url = watch.getImageUrl(type, 'soldier_full').replace('(', '%28').replace(')', '%29');
    style.backgroundImage = 'url(' + url + ')';
  }

  const family_image = {};
  if (family.family_back_image !== null) {
    family_image.backgroundImage = `url(${family.family_back_image})`;
  }

  if (last === 3) {
    fill.push(<div className="spacer" style={family_image} key={`spacer-${family.id}`}></div>);
  }
  else if (last === 2) {
    fill.push(<div className={`spacer col-2 img-${noImage ? 'no-image' : type}`} style={style} key={`spacer-${family.id}`}></div>);
  }
  else if (last === 1) {
    fill.push(<div style={style} key={`spacer-2-${family.id}`} className={`spacer col-2 img-${noImage ? 'no-image' : type}`}></div>);
    fill.push(<div className="spacer" style={family_image} key={`spacer-1-${family.id}`}></div>);
  }
  return fill;
}

function WatchGroup(props) {
  const { item } = props;

  const last = (item.items.length + 1) % 4;

  const title = item.name ? item.name : '';

  const family_image = {};
  if (item.family_front_image !== null) {
    family_image.backgroundImage = `url(${item.family_front_image})`;
  }

  return (
    <div className="watch-group grid">
      <div
        className="watch-group-item watch-group-title"
        style={family_image}
        key={`title-${item.id}`}>
        {'month' in item && (
          <div className="month">{ item.month }</div>
        )}
        <h2><SeparatedTitle value={title} /></h2>
      </div>
      {item.items.map(watch => (
        <WatchTeaser
          watch={watch}
          key={watch.sku}
          hide_novelties={props.hide_novelties} />
      ))}
      {fillers(last, item)}
    </div>
  );
}

export { getFamilyImage, WatchGroup as default };
