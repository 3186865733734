/* eslint-disable max-len */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import refWatch from 'assets/ref-watch.jpg';
import Modal from './Modal';
import ModalButton from './ModalButton';
import Straps from 'models/Straps';
import i18nWatcher from 'utils/i18nWatcher';

class StrapSearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.check = true;
    this.state = {
      focus: false,
      value: props.search ? props.search : '',
      error: false,
      lugValue: null,
      filterLugs: null,
      lang: props.i18n.language,
      isLoaded: false
    };
    this.modalRef = React.createRef();
    this.strapsLoaded = null;
  }

  onFocus = (e) => {
    this.setState({ focus: true, value: e.target.value });
  };

  onBlur = (e) => {
    this.setState({ focus: false, value: e.target.value });
  };

  onClear = (e) => {
    this.setState({ value: '', error: false });
  };

  onChange = (e) => {
    this.setState({ value: e.target.value });
  };
  
  onChangeLugs = (e) => {
    if(e.target.value) this.gotoSearch(null, e.target.value);
  };

  validate (e) {
    const val = this.cleanInput(this.state.value);
    if (this.isValid(val)) {
      this.gotoSearch(val);
    }
    else {
      this.setState({ error: true });
    }
  }

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.validate(e);
    }
  }

  onSearch = (e) => {
    this.validate(e);
  }

  onInput = (e) => {
    if (this.state.error) this.setState({ error: false });
  }

  cleanInput = str => {
    if (typeof str === 'string') {
      return str.replace(/\s/, '');
    }
    return !!str;
  };

  isValid = (str) => typeof str !== 'string' || str.length < 1 || str.replace('.', '').match(/[tT][0-9]{6,}/);

  gotoSearch(search, lug) {
    const params = [];
    if (search) params.push('sku=' + search);
    if (lug) params.push('lug=' + lug);
    this.props.history.push('/straps' + (params.length ? ('?' + params.join('&')) : ''));
  }

  load() {
    if (this.props.token && this.props.store) {
      const straps = new Straps(this.props.store, this.props.token);
      straps.sort = 'name';
      straps.page_size = 48;
      straps.search = null;
      straps.lang = this.state.lang;
      straps.page = 1;
      straps.noitems = true;
      straps.fetch(this, (model) => {
        this.setState({ filterLugs: model._data.meta.filter_strap_lugs, isLoaded: true })
      });
    }
  }

  componentDidMount() {
    i18nWatcher.add(this);
    this.load();
  }

  componentWillUnmount() {
    i18nWatcher.remove(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.search !== prevProps.search) {
      this.setState({ value: this.cleanInput(this.props.search) });
    }
  }

  render() {
    const { id, t } = this.props;
    const icon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.98 19.22"><title>search</title><path d="M17.57,19.22a1.45,1.45,0,0,1-1-.43L12.9,15a7.94,7.94,0,0,1-4.81,1.62A8.21,8.21,0,0,1,0,8.32,8.22,8.22,0,0,1,8.09,0a8.22,8.22,0,0,1,8.09,8.32,8.44,8.44,0,0,1-1.36,4.62l3.76,3.89a1.41,1.41,0,0,1,0,2A1.45,1.45,0,0,1,17.57,19.22ZM8.09,2.82a5.39,5.39,0,0,0-5.27,5.5,5.39,5.39,0,0,0,5.27,5.5,5.4,5.4,0,0,0,5.28-5.5A5.4,5.4,0,0,0,8.09,2.82Z" fill="#575756"/></svg>;
    const classes = ['search-form-straps'];
    if (this.state.focus || this.state.value.length > 0) {
      classes.push('focus');
    }
    return (
      <div className={classes.join(' ')}>
        <label htmlFor={id}>{t('Reference number')}</label>
        <div className="search-input">
          <div className="input-container">
            <div className="input-inside-container">
              <input
                id={id}
                placeholder={t('T127.410 or t0064071603300')}
                name="search"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                onInput={this.onInput}
                value={this.state.value} />
              <button type="button" className="btn-clear" onClick={this.onClear}>{t('Clear')}</button>
            </div>
            <ModalButton modalRef={this.modalRef} name="btn-info">
              <span className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                  <circle fill="#ff0000" cx="20" cy="20" r="20"/>
                  <path fill="#fff" d="M17.39,22.83a4.85,4.85,0,0,1,.28-1.76,3.65,3.65,0,0,1,.93-1.35c.64-.62,1.24-1.16,1.78-1.62a18.55,18.55,0,0,0,1.4-1.3,6.66,6.66,0,0,0,.92-1.14A2.16,2.16,0,0,0,23,14.54a2.28,2.28,0,0,0-.7-1.78,2.57,2.57,0,0,0-1.79-.64,3.24,3.24,0,0,0-2.47.9,3.51,3.51,0,0,0-.95,2.18l-5.32-.35a8,8,0,0,1,2.7-5.58,9,9,0,0,1,5.94-1.92,10.34,10.34,0,0,1,3,.43,7.3,7.3,0,0,1,2.47,1.3,6.26,6.26,0,0,1,1.7,2.14,6.69,6.69,0,0,1,.62,2.94A7.2,7.2,0,0,1,28,16a5.43,5.43,0,0,1-.71,1.61A9.18,9.18,0,0,1,26,19.19,23.1,23.1,0,0,1,23.92,21a3.5,3.5,0,0,0-1.07,1.21,2.79,2.79,0,0,0-.28,1.17v1H17.39Zm-.59,6.64a3.14,3.14,0,0,1,.25-1.25,2.93,2.93,0,0,1,.67-1,3.09,3.09,0,0,1,1-.68A3.22,3.22,0,0,1,20,26.29a3.31,3.31,0,0,1,1.25.24,3.09,3.09,0,0,1,1.69,1.69,3.31,3.31,0,0,1,.24,1.25,3.22,3.22,0,0,1-.24,1.24,3,3,0,0,1-.67,1,3.09,3.09,0,0,1-1,.68,3.31,3.31,0,0,1-1.25.24,3.22,3.22,0,0,1-1.24-.24,3.09,3.09,0,0,1-1-.68,2.88,2.88,0,0,1-.67-1A3.05,3.05,0,0,1,16.8,29.47Z"/>
                </svg>
              </span>
            </ModalButton>
          </div>
          <button type="button" className="btn-search" onClick={this.onSearch}>{icon}{t('Search')}</button>
        </div>
        {this.state.error && (
          <div className="message-error">
            {t('Please specify a reference number with at least 6 numbers')}
          </div>
        )}
        <p>
          {t('To find a compatible strap, enter the reference in the search field above. The website will display all compatible straps.')}
        </p>
        { this.state.filterLugs && (
          <>
            <h2>{ t('Or') }</h2>
            <div className="filter-lugs">
              <label htmlFor="filter-lugs">{ this.state.filterLugs.label }</label>
              <select
                id="filter-lugs"
                name="filter-lugs"
                className="lugs-switcher pimp-my-select"
                onChange={this.onChangeLugs}>
                <option value="">{ t('Select a value') }</option>
                {this.state.filterLugs.values.map(val => (
                  <option key={val.id} value={val.id}>{val.label}</option>
                ))}
              </select>
            </div>
          </>
        ) }
        { this.props.token && this.props.store && (
          <>
            <h2>{ t('Or') }</h2>
            <button type="button" className="btn" onClick={() => this.gotoSearch()}>
              {t('Search all straps')}
            </button>
          </>
        ) }
        <Modal ref={this.modalRef}>
          <p>{ t('straps.accessorization.ref_help') }</p>
          <img className="img-ref" src={refWatch} alt=""/>
        </Modal>
      </div>
    );
  }
}

export default withRouter((withTranslation()(StrapSearchForm)));
