import React from 'react';
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import AppContext from 'utils/AppContext';

function BackButton(props) {
  const { children, click, isChild } = props;
  if (isChild) {
    return (
      <div className="nav-collection--item parent">
        <button
          type="button"
          onClick={click}>
          { children }
        </button>
      </div>
    )
  }
  return (null);
}

class StaticNavigation extends React.Component {  
  constructor(props) {
    super(props);
    this.items = this.menuItems();
    this.state = {
      menu: this.items,
      isChild: false,
      title: '',
    };
    this.onBack = this.onBack.bind(this);
  }
  
  /*
   * t('About Tissot')
   * t('See all')
   * t('Innovators by tradition')
   * t('Swissness')
   * t('History')
   * t('Advertising campaign')
   * t('Sports partnerships')
   * t('Ambassadors')
   * t('Training')
   * t('Selling tips')
   * t('Technical features')
   * t('Merchandising')
   * t('Straps')
   */
  menuItems() {
    return [
      {
        title: 'About Tissot',
        key: 'about_tissot',
        children: [
          {
            title: 'See all',
            to: '/about_tissot/about',
            key: 'about_tissot_see_all',
          },
          {
            title: 'Innovators by tradition',
            to: '/about_tissot/innovator_by_tradition',
            key: 'innovator_by_tradition',
          },
          {
            title: 'Swissness',
            to: '/about_tissot/swissness',
            key: 'swissness',
          },
          {
            title: 'History',
            to: '/about_tissot/history',
            key: 'history',
            condition: (context) => {
              return !context.stores[context.store].hide_yt_video;
            },
          },
          {
            title: 'Sports partnerships',
            to: '/about_tissot/sports',
            key: 'sports',
          },
          {
            title: 'Ambassadors',
            to: '/about_tissot/ambassadors',
            key: 'ambassadors',
          }
        ]
      },
      {
        title: 'Straps Accessorization',
        key: 'straps-page',
        to: '/straps-page',
      },
      {
        title: 'Technical features',
        key: 'technical_features',
        to: '/technical_features',
      },
      {
        title: 'Merchandising',
        key: 'merchandising',
        to: '/merchandising',
      },
      {
        title: 'Customer services',
        key: 'customer_services',
        to: '/customer-services',
      },
      {
        title: 'About this tool',
        key: 'about',
        to: '/about',
      },
    ];
  }
  
  onBack() {
    this.props.selectedMenu(null);
    this.setState({
      menu: this.items,
      title: '',
      isChild: false
    });
  }
  
  nav(items, title) {
    this.props.selectedMenu('static');
    this.setState({
      menu: items,
      title: title,
      isChild: true
    });
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.status !== this.props.status &&
      this.props.status === null
    ) {
      this.setState({
        menu: this.items,
        title: '',
        isChild: false
      });
    }
  }
  
  render() {
    const { close, t } = this.props;
    const { menu, isChild, title } = this.state;
    return (
      <div className="nav-static">
        <BackButton click={this.onBack} isChild={isChild}>{t(title)}</BackButton>
        {menu.map((item) => {
          if ('condition' in item && !item.condition(this.context)) {
            return null;
          }
          if (item.children && item.children.length > 0) {
            return (
              <div key={item.key} className="nav-collection--item">
                <button
                  type="button"
                  onClick={() => this.nav(item.children, item.title)}>
                  {t(item.title)}
                </button>
              </div>
            );
          }
          return (
            <div key={item.key} className="nav-collection--item">
              <Link to={item.to} onClick={close}>{t(item.title)}</Link>
            </div>
          );
        })}

      </div>
    );
  }
}

StaticNavigation.contextType = AppContext;
export default withTranslation()(StaticNavigation);
