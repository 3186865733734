import { t } from 'i18next';
import React, { useContext, useEffect } from 'react';
import AppContext from 'utils/AppContext'

function FilterButton(props) {
  const { meta, filter, productsPage } = props;
  const index = (filter.name in meta ) ? meta[filter.name].values.findIndex((el) => el.id === filter.value) : -1;

  const removeFilter = () => {
    productsPage.setFilter(filter.name, null);
  };

  useEffect(() => {
    if (index === -1) {
      productsPage.setFilter(filter.name, null);
    }
  }, [index, productsPage, filter.name]);

  if (index === -1) {
    return null;
  }

  return (
    <button type="button" className="btn btn-remove-filter" onClick={removeFilter}>
      <span className="label">{ meta[filter.name].label }:</span>
      { meta[filter.name].values[index].label }
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <path fill="#ffffff" d="M4,6L6,4L14,12L12,14Z"></path>
        <path fill="#ffffff" d="M12,4L14,6L6,14L4,12Z"></path>
      </svg>
    </button>
  )
}

function FilterButtons(props) {
  const context = useContext(AppContext);
  if (context.activeFilters.length < 1) {
    return null;
  }
  return (
    <div className="filter-buttons">
      {context.activeFilters.map(filter => (
        <FilterButton
          meta={props.meta}
          key={filter.name}
          productsPage={props.productsPage}
          filter={filter} />
      ))}
      <button className="btn btn-remove-filter btn-reset" type="button" onClick={() => props.productsPage.resetFilters()}>
        {t('Reset filters')}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
          <path fill="#575756" d="M4,6L6,4L14,12L12,14Z"></path>
          <path fill="#575756" d="M12,4L14,6L6,14L4,12Z"></path>
        </svg>
      </button>
    </div>
  );
}

export default FilterButtons;