import Model from 'models/Model';
import i18n from 'i18next';
import ReactGA from 'react-ga';
import Storage from 'utils/Storage';

class Context extends Model {
  constructor(appContext) {
    super('api/context');
    this.setGetParam('token', appContext.token);
    this.appContext = appContext;
  }

  set token(token) {
    if (this.params && 'token' in this.params) {
      this.params.token = token;
    }
    this._token = token;
  }

  get token() {
    return this._token;
  }

  get user() {
    return this.data.user;
  }

  get languages() {
    return this.data.languages;
  }

  get stores() {
    return this.data.stores;
  }

  onFetch() {
    this.appContext.updateLanguages(this.languages);
    this.appContext.updateStores(this.stores);
    this.appContext.setUser(this.user);
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE, {
      debug: false,
      titleCase: false,
      gaOptions: {
        userId: this.user.username
      }
    });
    
    let store = Storage.getItem('store');
    if (!store || !(store in this.stores)) {
      [store] = Object.keys(this.stores);
    }
    this.appContext.setStore(store);

    let lang = Storage.getItem('language');
    if (!lang) {
      lang = this.stores[store].lang.code;
      let langExists = false;
      for (const keylang in this.languages) {
        if (this.languages[keylang].locale === lang) {
          langExists = true;
          lang = keylang;
          break;
        }
      }
      if (!langExists) {
        lang = 'en';
      } 
    }
    i18n.changeLanguage(lang);
    ReactGA.set({ dimension3: lang });
    window.document.documentElement.lang = lang;
  }
}

export default Context;
