import React, { useContext } from 'react';
import i18n from 'i18next';
import AppContext from 'utils/AppContext'
import ProductPriceComment from './ProductPriceComment';

function ProductPrice(props) {
  const context = useContext(AppContext);
  if (context.hidePrice || props.product.price === null) {
    return null
  }
  return (
    <div className="price">
      <div className="price-price">{props.product.price}</div>
      <div className="price-comment"><ProductPriceComment/></div>
      <div className="price-comment">{context.stores[context.store].country.name[i18n.language]}</div>
    </div>
  );
}

export default ProductPrice;
