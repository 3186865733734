import Model from 'models/Model';

class Strap extends Model {
  constructor(data, store, token) {
    super('api/{store}/strap/detail/{sku}/');
    this.setGetParam('token', token);
    this.store = store;
    this._sku = null;
    this.images = {
      view: null, //
      lugs: null, //
      base: null, // Soldat
    };
    if (data) {
      this.data = data;
      this._sku = data.sku;
    }
  }

  set store(store) {
    if (this.params && 'store' in this.params) {
      this.params.store = store;
    }
    this._store = store;
  }

  get store() {
    return this._store;
  }

  set sku(sku) {
    if (this.params && 'sku' in this.params) {
      this.params.sku = sku;
    }
    this._sku = sku;
  }

  get sku() {
    return this._sku;
  }

  get originalSku() {
    if ('attributes' in this._data) {
      return this._data.attributes.original_sku.value;
    }
    return null;
  }

  get name() {
    return this._data.name;
  }

  get price() {
    return this._data.price;
  }

  get soldier() {
    return this.getImageUrl('base', 'soldier');
  }

  get stock() {
    return this._data.stock_indicator;
  }

  get is_novelties() {
    return this._data.is_novelties;
  }

  get video() {
    if ('video_url' in this._data.attributes && this._data.attributes.video_url) {
      return this._data.attributes.video_url.value;
    }
    return null;
  }

  get related() {
    return this._data.linked_product_items;
  }

  get launch_date() {
    return this._data.launch_date;
  }

  set data(data) {
    data.attrs = {};
    this._data = data;
    this.parseImages();
  }

  getAttribute(name) {
    let value = null;
    let label = null;
    if (typeof name === 'string') {
      if (name in this._data.attributes) {
        const attr = this._data.attributes[name];
        label = attr.label;
        if (Array.isArray(attr.value)) {
          value = attr.value.map((val) => val.label).join(', ');
        } else if (typeof attr.value === 'string') {
          value = attr.value;
        } else if ('label' in attr.value) {
          value = attr.value.label;
        }
      }
    }
    else {
      label = name.title;
      value = name.value(this._data);
    }
    return { value: value,
      label: label }
  }

  getImageUrl(type, style) {
    if (!this.images[type] || this.images[type] === null) {
      return null;
    }
    const styles = {
      soldier: '?im=Resize=(400,400)',
      soldier_full: '?im=Resize=(700,700)'
    };
    const st = (style in styles) ? styles[style] : '';
    return process.env.REACT_APP_IMAGE_BASE_URL + this.images[type] + st;
  }

  getAllImages() {
    const images = [];
    let sizeTotal = 0;
    for (const type in this.images) {
      if (type !== 'base') {
        images.push({
          name: 'img-strap-' + type,
          url: this.getImageUrl(type, 'soldier'),
          size: 1
        });
        sizeTotal += 1;
      }
    }
    return {
      size: sizeTotal,
      images: images
    };
  }

  getLimitedAvailability() {
    if ('limited_availability' in this._data) {
      return this._data.limited_availability
    }
    return false
  }

  isSampleToOrder() {
    if ('is_sample' in this._data) {
      return this._data.is_sample
    }
    return false
  }

  parseImages() {
    for (let i = 0; i < this._data.media_gallery_entries.length; i++) {
      if (this._data.media_gallery_entries[i].file.match(/view/i)) {
        this.images.view = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].file.match(/lugs/i)) {
        this.images.lugs = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].types.includes('main_without_shadow')) {
        this.images.base = this._data.media_gallery_entries[i].file;
      }
    }
    if (this.images.base === null && this._data.media_gallery_entries.length > 0) {
      this.images.base = this._data.media_gallery_entries[0].file;
    }

  }
}

export default Strap;
