import React from 'react';
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';
import ReactGA from 'react-ga';

function UserManualLink(props) {
  const { t } = useTranslation();
  if (props.product.getAttribute('reference_usermanualid').value === null) {
    return null;
  }
  const userManualURL = process.env.REACT_APP_USERMANUAL_URL
    .replace('{id}', props.product.getAttribute('reference_usermanualid').value)
    .replace('{lang}', i18n.language);
    
  const click = () => {
    ReactGA.event({
      category: 'Téléchargements',
      action: 'User manual'
    });
  };
  
  return (
    <a className="btn" onClick={click} href={userManualURL} rel="noopener noreferrer" target="_blank">
      {t('Download user manual')}
    </a>
  );
}

export default UserManualLink;
