const CACHE_NAME = 'app-cache';
const FAKE_ENDPOINT = '/cache-endpoint';

let _data = {};

const Storage = {
  getItem: function(bag) {
    return _data[bag];
  },
  setItem: function(bag, value, cb) {
    _data[bag] = value;
    Storage.save(cb);
  },
  load: async function() {
    try {
      const cache = await caches.open(CACHE_NAME);
      const response = await cache.match(FAKE_ENDPOINT);

      if (!response) {
        return null;
      }

      const responseBody = await response.json();
      _data = responseBody;
      return _data;
    } catch (error) {
      console.error('get cache error:', { error });
    }
    return null;
  },
  save: async function(cb) {
    try {
      const cache = await caches.open(CACHE_NAME);
      const responseBody = JSON.stringify(_data);
      const response = new Response(responseBody);
      await cache.put(FAKE_ENDPOINT, response);
      if (cb) cb();
    } catch (error) {
      console.error('save cache error:', { error });
    }
  }
};

export default Storage;