/* eslint camelcase: "off" */
import Model from 'models/Model';

class ProductList extends Model {
  constructor(endpoint, store, token) {
    super(endpoint);
    this.setGetParam('token', token);
    this.store = store;
    this._filter_family = null;
    this._filter_subfamily = null;
    this._filter_strap = null;
    this._filter_gender = null;
    this._filter_diameter = null;
    this._filter_dial_color = null;
    this._filter_movement_type = null;
    this._sort = null;
    this._page = null;
    this._page_size = null;
    this._category = null;
    this._launch_date = null;
    this._filter_novelty = null;
    this._search = null;
    this._family = null;
  }

  set store(store) {
    if (this.params && 'store' in this.params) {
      this.params.store = store;
    }
    this._store = store;
  }

  get store() {
    return this._store;
  }

  set filter_family(filter_family) {
    this.setGetParamCheck('filter_family', filter_family);
    this._filter_family = filter_family;
  }

  get filter_family() {
    return this._filter_family;
  }

  set filter_subfamily(filter_subfamily) {
    this.setGetParamCheck('filter_subfamily', filter_subfamily);
    this._filter_subfamily = filter_subfamily;
  }

  get filter_subfamily() {
    return this._filter_subfamily;
  }

  set filter_strap(filter_strap) {
    this.setGetParamCheck('filter_strap', filter_strap);
    this._filter_strap = filter_strap;
  }

  get filter_strap() {
    return this._filter_strap;
  }

  set filter_gender(filter_gender) {
    this.setGetParamCheck('filter_gender', filter_gender);
    this._filter_gender = filter_gender;
  }

  get filter_gender() {
    return this._filter_gender;
  }

  set filter_diameter(filter_diameter) {
    this.setGetParamCheck('filter_diameter', filter_diameter);
    this._filter_diameter = filter_diameter;
  }

  get filter_diameter() {
    return this._filter_diameter;
  }

  set filter_dial_color(filter_dial_color) {
    this.setGetParamCheck('filter_dial_color', filter_dial_color);
    this._filter_dial_color = filter_dial_color;
  }

  get filter_dial_color() {
    return this._filter_dial_color;
  }

  set filter_movement_type(filter_movement_type) {
    this.setGetParamCheck('filter_movement_type', filter_movement_type);
    this._filter_movement_type = filter_movement_type;
  }

  get filter_movement_type() {
    return this._filter_movement_type;
  }

  set sort(sort) {
    if (sort !== 'search') {
      if (sort === 'novelties_asc' || sort === 'novelties_desc') {
        this.setGetParamCheck('order', sort === 'novelties_asc' ? 'asc' : 'desc');
      } else {
        this.setGetParamCheck('sort', sort);
      }
    }        
    this._sort = sort;
  }

  get sort() {
    return this._sort;
  }

  set page(page) {
    this.setGetParamCheck('page', page);
    this._fpage = page;
  }

  get page() {
    return this._page;
  }

  set page_size(page_size) {
    this.setGetParamCheck('page_size', page_size);
    this._fpage_size = page_size;
  }

  get page_size() {
    return this._page_size;
  }

  set category(category) {
    this.setGetParamCheck('category', category);
    this._category = category;
  }

  get category() {
    return this._category;
  }
  
  set family(family) {
    this.setGetParamCheck('sub_sub_family', family);
    this._family = family;
  }

  get family() {
    return this._family;
  }

  set launch_date(launch_date) {
    this.setGetParamCheck('launch_date', launch_date);
    this._launch_date = launch_date;
  }

  get launch_date() {
    return this.__launch_date;
  }

  set filter_novelty(filter_novelty) {
    this.setGetParamCheck('filter_novelty', filter_novelty);
    this._filter_novelty = filter_novelty;
  }

  get filter_novelty() {
    return this.__filter_novelty;
  }

  set search(search) {
    this.setGetParamCheck('search', search);
    this._search = search;
  }

  get search() {
    return this._search;
  }

}

export default ProductList;
