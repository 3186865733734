/* eslint-disable react/no-danger */
import React from 'react';
import AppContext from 'utils/AppContext';
import { withTranslation } from 'react-i18next';
import aboutHead2 from 'assets/about-head2.jpg';
import tradition from 'assets/tradition.jpg';
import sports3 from 'assets/sports3.jpg';
import history1 from 'assets/history1.jpg';
import swiss from 'assets/swiss.jpg';
import swissness1 from 'assets/swissness1.jpg';
import swissness2 from 'assets/swissness2.jpg';
import swissness3 from 'assets/swissness3.jpg';
import ambassadors1 from 'assets/ambassadors1.jpg';
import ambassadors5 from 'assets/ambassadors5.jpg';
import ambassadors6 from 'assets/ambassadors6.jpg';
import ambassadors2b from 'assets/ambassadors2-1.jpg';
import ambassadors3c from 'assets/ambassadors3-2.jpg';
import ambassadors4 from 'assets/ambassadors4.jpg';
import ambassadors4a from 'assets/ambassadors4-1.jpg';
import ambassadors4b from 'assets/ambassadors4-2.jpg';
import AboutPartners from 'components/AboutPartners';
import AOS from 'aos';
import SmoothScroll from 'smooth-scroll';
import 'aos/dist/aos.css';

class AboutTissotPage extends React.Component {

  componentDidMount() {
    AOS.init({
      duration: 400,
      delay: 300
    });
    this.scroll = new SmoothScroll(null, {
      updateURL: false,
      offset: 100,
    });
    if (this.props.match.params.section) {
      setTimeout(() => this.scrollTo(this.props.match.params.section), 100);
    }
  }

  scrollTo(section) {    
    const anchor = document.querySelector('#' + section);
    if (anchor) this.scroll.animateScroll(anchor);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.section) {
      this.scrollTo(this.props.match.params.section);
    }
  }

  render() {
    const { t } = this.props;
    const { stores, store } = this.context;
    return (
      <div id="about" className="about-tissot">
        <div className="head">
          <div className="bg">
            <div className="image"></div>
          </div>
          <div className="container grid">
            <div className="col-2"></div>
            <div className="col-2">
              <h1>{t('About Tissot')}</h1>
              <div className="chapo">{t('about.chapo')}</div>
              <div
                className="chapo-text text-base"
                dangerouslySetInnerHTML={{ __html: t('about.chapo2') }}>
              </div>
            </div>
          </div>
        </div>
        <div id="innovator_by_tradition" className="innovators page-section">
          <div className="content">
            <div className="container">
              <div className="grid">
                <div className="col-2">
                  <h2 data-aos="fade-left">{t('Innovators by tradition')}</h2>
                  <h3>{t('about.innovators.subtitle')}</h3>
                  <div className="grid">
                    <div>
                      <div
                        className="text-base"
                        dangerouslySetInnerHTML={{ __html: t('about.innovators.text') }}>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
                <div>
                  <img src={aboutHead2} alt="" data-aos="flip-left" />
                </div>
                <div>
                  <img className="tradition" src={tradition} alt="" data-aos="flip-left" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="swissness" className="swissness page-section">
          <div className="img-circle">
            <img src={swissness1} alt="" data-aos="fade-right" />
            <div className="legend left">{t('about.swissness.note1')}</div>
          </div>
          <div className="container">
            <div className="grid">
              <div></div>
              <div className="col-3">
                <h2 className="big">{t('Swissness')}</h2>
              </div>
            </div>
            <div className="grid">
              <div></div>
              <div className="col-text">
                <h3>{t('about.swissness.title')}</h3>
                <div
                  className="chapo"
                  dangerouslySetInnerHTML={{ __html: t('about.swissness.chapo') }}>
                </div>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: '<img src=' + swiss + ' class="swiss-2" alt="" />' + t('about.swissness.text') }}>
                </div>
                <img src={swiss} className="swiss" alt="" />
              </div>
              <div className="col-2">
                <img src={swissness2} alt="" data-aos="flip-left" />
                <div className="grid">
                  <div></div>
                  <div>
                    <div className="legend">{t('about.swissness.note2')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-3">
              <div></div>
              <div className="col-2">
                <img src={swissness3} alt="" data-aos="flip-left" />
                <div className="legend">{t('about.swissness.note3')}</div>
              </div>
              <div
                className="text-base"
                dangerouslySetInnerHTML={{ __html: t('about.swissness.text2') }}>
              </div>
            </div>
          </div>
        </div>
        {!stores[store].hide_yt_video && (
          <div id="history" className="history page-section">
            <div className="container">
              <div className="grid bottom">
                <div className="col-2">
                  <h2>{t('History')}</h2>
                  <h3>{t('about.history.subtitle')}</h3>
                  <div className="img-history">
                    <img src={history1} alt="" data-aos="fade-left" />
                  </div>
                </div>
                <div className="col-2">
                  <div className="video">
                    <iframe
                      title="video"
                      width="652"
                      height="406"
                      src={`https://www.youtube.com/embed/${t('about.history.videoUrl')}?rel=0&modestbranding=1`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen>
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div id="sports" className="sports page-section">
          <div className="head">
            <div className="img img-1"></div>

            <div className="content">
              <h2>{t('Sports partnerships')}</h2>
              <h3>{t('about.sports.subtitle')}</h3>
              <div
                className="text-base"
                dangerouslySetInnerHTML={{ __html: t('about.sports.text') }}>
              </div>
            </div>
            <div className="img img-2"></div>
          </div>
          <div className="container">
            <div className="grid">
              <div className="col-2 hide-small">
                <img src={sports3} alt="" data-aos="fade-right" />
              </div>
              <div className="col-2">
                <AboutPartners />
              </div>
            </div>
          </div>
        </div>
        <div id="ambassadors" className="ambassadors page-section">
          <div className="img-1 img-num pos-right hide-small" data-num="1">
            <img src={ambassadors1} alt="" data-aos="fade-right" />
          </div>
          <div className="container">
            <div className="grid">
              <div></div>
              <div className="col-2">
                <h2>{t('TISSOT ambassadors')}</h2>
              </div>
              <div></div>
            </div>
            <div className="grid">
              <div></div>
              <div>
                <img className="img-1 show-small" src={ambassadors1} alt="" />
                
                <h3>1. {t('about.ambassadors.2.name')} /</h3>
                <h4>{t('about.ambassadors.2.role')}.</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.2.desc') }}>
                </div>

                <img className="show-small" src={ambassadors2b} alt="" />
                <h3>2. {t('about.ambassadors.1.name')} /</h3>
                <h4>{t('about.ambassadors.1.role')}.</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.1.desc') }}>
                </div>
              </div>
              <div className="col-2 hide-small">
                <p className="img-3 img-num" data-num="2"><img src={ambassadors2b} alt="" data-aos="fade-left" /></p>
              </div>
            </div>
          </div>
          <div className="container margin-2">
            <div className="grid">
              <div className="col-1">
                <div className="img-5 img-num pos-top pos-right" data-num="3">
                  <img src={ambassadors6} alt="" data-aos="fade-right" />
                </div>
              </div>
              
              <div>
                <h3 className="no-margin">3. {t('about.ambassadors.3.name')} /</h3>
                <h4>{t('about.ambassadors.3.role')}.</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.3.desc') }}>
                </div>

              </div>
              <div className="order-4">
                <h3 className="no-margin">4. {t('about.ambassadors.8.name')} /</h3>
                <h4>{t('about.ambassadors.8.role')}.</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.8.desc') }}>
                </div>

              </div>
              <div className="order-3">
                <div className="img-5 img-num pos-top" data-num="4">
                  <img src={ambassadors3c} alt="" data-aos="fade-left" />
                </div>
              </div>
            </div>
            <div className="grid">
              <div></div>
              <div>
                <img className="show-small" src={ambassadors5} alt="" />
                <h3>5. {t('about.ambassadors.10.name')} /</h3>
                <h4>{t('about.ambassadors.10.role')}.</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.10.desc') }}>
                </div>
              </div>
              <div className="col-2 hide-small">
                <p className="img-3 img-num pos-top" data-num="5"><img src={ambassadors5} alt="" data-aos="fade-left" /></p>
              </div>
            </div>
          </div>
          <div className="container margin-2">
            <div className="grid">
              <div className="img-num pos-top pos-right" data-num="6">
                <img src={ambassadors4} alt="" data-aos="fade-right" />
              </div>
              <div>
                <h3 className="no-margin">6. {t('about.ambassadors.6.name')} /</h3>
                <h4>{t('about.ambassadors.6.role')}.</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.6.desc') }}>
                </div>
                <img className="show-small" src={ambassadors4a} alt="" />
                <h3>7. {t('about.ambassadors.7.name')} /</h3>
                <h4>{t('about.ambassadors.7.role')}.</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.7.desc') }}>
                </div>
              </div>
              <div className="col-2 img-num pos-top hide-small" data-num="7">
                <img src={ambassadors4a} alt="" data-aos="fade-left" />
              </div>
            </div>
          </div>
          <div className="container margin-2">
            <div className="grid">
              <div>
              </div>
              <div className="img-num pos-top" data-num="8">
                <img src={ambassadors4b} alt="" data-aos="fade-right" />
              </div>
              <div>
                <h3 className="no-margin">8. {t('about.ambassadors.9.name')} /</h3>
                <h4>{t('about.ambassadors.9.role')}.</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.9.desc') }}>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AboutTissotPage.contextType = AppContext;

export default withTranslation()(AboutTissotPage);
