import React, { useState } from 'react';
import Dropdown from 'components/Dropdown';
import { useTranslation } from 'react-i18next';

function getSorts(t) {
  return {    
    family: t('Family'),
    name: t('Name'),
    price: t('Price - low'),
    '-price': t('Price - high'),
    bestseller: t('Best seller'),
  }
}
function Sort(props) {
  const { t } = useTranslation();
  const [sort, setSort] = useState(props.productsPage.state.sort);
  const list = getSorts(t);
  if (props.enable_novelties) {
    list.novelties_asc = t('Novelties - oldest');
    list.novelties_desc = t('Novelties - newest');
  }
  
  if (props.enable_search) {
    list.search = t('Search');
  }
  
  const onSortChange = function(name) {
    setSort(name);
    props.productsPage.setSort(name);
  };
  
  const buttons = [];
  for (const name in list) {
    const btnProps = {
      onClick: () => { onSortChange(name) },
      className: 'btn-filter'
    };
    if (sort === name) {
      btnProps.className += ' active';
    }
    buttons.push(
      <button key={name} type="button" {...btnProps}>
        {list[name]}
      </button>
    );
  }
  return (
    <Dropdown title={t('Sort')}>
      <div className="sort-list">
        {buttons}
      </div>
    </Dropdown>
  );
}

export default Sort;