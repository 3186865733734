/* eslint-disable react/no-unused-state */
import React from 'react';
import Spinner from 'components/Spinner';
import Straps from 'models/Straps';
import StrapTeaser from 'components/StrapTeaser';
import StrapsFilterNav from 'components/filters/StrapsFilterNav';
import i18nWatcher from 'utils/i18nWatcher';
import i18n from 'i18next';
import { useTranslation, withTranslation } from 'react-i18next'
import AppContext from 'utils/AppContext';
import Breadcrumb from 'components/nav/Breadcrumb';
import ProductPriceComment from 'components/product/ProductPriceComment';
import StrapSearchForm from 'components/StrapSearchForm';
import WatchTeaser from 'components/WatchTeaser';
import { Link } from 'react-router-dom';

function LoadMore(props) {
  const { t } = useTranslation();
  if (props.loaded) {
    return (
      <div className="load-more">
        <button className="btn" type="button" onClick={props.click}>{t('View more')}</button>
      </div>
    );
  }
  else {
    return <div className="load-more"><Spinner/></div>
  }
}

function Spacers(props) {
  const { length } = props;
  if (length < 1) return null;
  const loops = (length + 1) % 4;
  if (loops < 1) return null;
  const spacers = [];
  for (let i = 0; i < loops; i++) {
    spacers.push(<div key={i}></div>);
  }
  return spacers;
}

function WatchTeaserLoaded(props) {
  const { t } = useTranslation();
  return (
    <WatchTeaser watch={props.watch} hide_diameter="true" hide_stock="true" hide_pictos="true">
      <div className="teaser-title">
        {t('Compatible straps for:')}
      </div>
    </WatchTeaser>
  );
}

function WatchTeaserNotLoaded(props) {
  return (
    <div className="watch-teaser" key="watch-teaser-not-loader">
      <Spinner />
    </div>
  );
}

function StrapSearchBlock(props) {
  return (
    <div className="strap-first-block" key="strap-search-form">
      <StrapSearchForm id="strap-search-page-form" search={props.search} />
    </div>
  );
}

class StrapsList extends React.Component {

  constructor(props) {
    super(props);
    this.startLoaded = false;
    this.state = {
      isLoaded: false,
      loadMoreLoaded: true,
      data: null,
      lang: i18n.language,
      loadMore: false,
      watch: null,
      sort: props.default_sort ?? 'name'
    };

    this.setFilter = this.setFilter.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.load = this.load.bind(this);
  }

  addProducts() {
    const { store, token } = this.context;

    const products = new Straps(store, token);
    const filters = this.context.getStrapsFiltersList();

    products.sort = this.state.sort;
    products.page_size = this.products.length < 1 ? 47 : 48;
    products.search = this.props.search || this.props.sku;
    products.lang = this.state.lang;
    products.page = this.products.length + 1;

    this.context.activeFilters.forEach(function(filter) {
      if (filters.indexOf(filter.name) !== -1) products[filter.name] = filter.value;
    });
    products.fetch(this, (model) => {
      if (model.data.meta.count < 1 && this.context.activeFilters.size > 0) {
        this.resetFilters();
      }
      else {
        this.products.push(model.data);
        this.setStorage();
        this.setState({
          isLoaded: true,
          data: model,
          loadMoreLoaded: true,
          loadMore: model.data.meta.count_page > this.products.length,
          forceUpdate: false
        });
      }
    });
  }

  handleScroll(event) {
    try {
      sessionStorage.setItem('straps-scroll', window.scrollY);
    } catch (e) {
      try {
        sessionStorage.removeItem('straps-scroll');
      } catch (e2) {
        // continue regardless of error
      }
    }
  }

  setStorage() {
    const data = {
      products: this.products,
      filters: [],
      sort: this.state.sort,
      search: this.props.search || this.props.sku,
      store: this.context.store,
      lang: this.state.lang
    };
    this.context.activeFilters.forEach(function(filter) {
      data.filters.push(filter);
    });
    try {
      sessionStorage.setItem('straps-data', JSON.stringify(data));
    } catch (e) {
      try {
        sessionStorage.removeItem('straps-data');
      } catch (e2) {
        // continue regardless of error
      }
    }
  }

  load(firstLoad) {
    if (firstLoad && this.props.isBackBtn) {
      const data = sessionStorage.getItem('straps-data');
      if (data) {
        const parsedData = JSON.parse(data);
        const search = this.props.search || this.props.sku;
        if (parsedData.lang === this.state.lang &&
          parsedData.store === this.context.store &&
          parsedData.search === search
        ) {
          this.products = parsedData.products;
          const { meta } = this.products[this.products.length - 1];
          const newState = {
            isLoaded: true,
            loadMoreLoaded: true,
            loadMore: meta.count_page > this.products.length,
            preventReload: true,
            sort: parsedData.sort
          };
          if ('filters' in parsedData) {
            this.context.setFilters(parsedData.filters);
          }

          this.setState(newState, function() {
            const scroll = sessionStorage.getItem('straps-scroll');
            window.scroll(0, scroll);
          });
          return;
        }
      }
    }
    this.products = [];
    this.addProducts();
  }

  onLoadMore = () => {
    this.setState({ loadMoreLoaded: false });
    this.addProducts();
  };

  componentDidMount() {
    if (!this.startLoaded) {
      this.startLoaded = true;
      i18nWatcher.add(this);
      window.addEventListener('scroll', this.handleScroll);
      if (this.props.lug) {
        this.context.addFilter({
          name: 'filter_strap_lugs',
          value: this.props.lug
        });
      }
      else {
        this.load(true);
      }
      
    }
    
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    i18nWatcher.remove(this);
  }

  getProducts() {
    const products = {
      items: [],
      meta: this.products[this.products.length - 1].meta
    };

    for (let i = 0; i < this.products.length; i++) {
      products.items = products.items.concat(this.products[i].items);
    }
    return products;
  }

  setFilter(name, value) {
    if (value === null) {
      this.context.removeFilter({ name: name, value: value });
    }
    else {
      this.context.addFilter({ name: name, value: value });
    }
  }

  getActiveFilterId(id) {
    return this.context.getFilter(id);
  }

  setSort(name) {
    if (name !== this.state.sort) {
      this.setState({ sort: name, isLoaded: false }, () => this.load());
    }
  }

  resetFilters() {
    this.context.resetFilters('straps');
  }

  hasFilters() {
    return this.context.activeFilters.length > 0;
  }

  componentDidUpdate(prevProps, prevState) {
    const newState = { isLoaded: false };
    const filtersHasChange = this.props.filtersHash !== prevProps.filtersHash;
    if (
      this.props.collection !== prevProps.collection ||
      this.props.search !== prevProps.search ||
      this.props.sku !== prevProps.sku ||
      filtersHasChange
    ) {
      this.setState(newState);
      this.load(this.props.collection !== prevProps.collection);
    } else if (this.props.watch !== prevProps.watch) {
      this.setState({ watch: this.props.watch });
    }
  }

  render() {
    const { isLoaded, loadMore, loadMoreLoaded } = this.state;
    const { t } = this.props;

    let firstBlock = '';
    if (this.props.sku) {
      if (this.state.watch) {
        firstBlock = <WatchTeaserLoaded watch={this.state.watch} />;
      }
      else {
        firstBlock = <WatchTeaserNotLoaded />;
      }
    }
    else {
      firstBlock = <StrapSearchBlock search={this.props.search} />
    }

    if (!isLoaded) {
      return <Spinner position="center"/>;
    } else {
      const { items, meta } = this.getProducts();
      return (
        <>
          {this.state.watch && (
            <Breadcrumb menu={this.state.watch.menu}>
              <Link to={'/product/' + this.state.watch.sku} key={this.state.watch.sku}>
                {this.state.watch.originalSku}
              </Link>
            </Breadcrumb>
          )}
          {this.props.search && (
            <Breadcrumb>
              <Link to="/straps-page">
                {t('Straps')}
              </Link>
            </Breadcrumb>
          )}
          <StrapsFilterNav
            meta={meta}
            productsPage={this} />
          <div className="watches-list straps-list grid">
            {firstBlock}
            {items.map(strap => (
              <StrapTeaser
                strap={strap}
                key={strap.sku}
                context={this.props.sku ? 'watch' : 'search'}
                data={this.props.sku ? this.props.sku : this.props.search} />
            ))}
            {items.length < 1 && (
              <div className="strap-empty-block col-3"><h2>{t('No products match your search')}</h2></div>
            )}
            <Spacers length={items.length} />
          </div>

          <p className="price-legend">* <ProductPriceComment/></p>
          {loadMore &&
            <LoadMore loaded={loadMoreLoaded} click={this.onLoadMore} />
          }
        </>
      );
    }
  }
}
StrapsList.contextType = AppContext;
StrapsList.defaultProps = {
  collection: null,
  search: null
};

export default withTranslation()(StrapsList);
