import React, { useContext } from 'react';
import ProductsList from 'components/productlist/ProductsList';
import AppContext from 'utils/AppContext';

function NoveltiesPage(props) {
  const context = useContext(AppContext);
  return (
    <div className="container">      
      <ProductsList
        key="noveltieslist"
        showBreadcrumb={false}
        default_sort="novelties_desc" 
        launch_date={props.match.params.launch_date ?? 'all'}
        isBackBtn={props.isBackBtn}
        filtersHash={props.filtersHash}
        store={context.store} />
    </div>
  );
}

export default NoveltiesPage;
