import React from 'react';
import AppContext from 'utils/AppContext';

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: this.props.productsPage.getActiveFilterId(this.props.id)
    };
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(id) {
    this.setState(
      (prevState) => ({ activeId: (prevState.activeId === id ? null : id) }),
      () => this.props.productsPage.setFilter(this.props.id, this.state.activeId)
    );

  }

  render() {
    const { values, label } = this.props.filter;
    if (values.length > 1 || this.state.activeId) {
      return (
        <div className="filter-filter">
          {label && (
            <h3>{label}</h3>    
          )}
          {values.map(filter => (
            <button
              className={`btn-filter${(filter.id === this.state.activeId) ? ' active' : ''}`}
              key={filter.id}
              type="button"
              onClick={() => this.changeValue(filter.id)}>
              {filter.label}
            </button>
          ))}
        </div>
      );
    }
    return null
  }
}
Filter.contextType = AppContext;

export default Filter;
