import React from 'react';
import { withTranslation } from 'react-i18next';

class StrapsTechnicalSpecs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'details'
    };
  }

  setSelectedTab = (selectedTab) => {
    this.setState({
      selectedTab: selectedTab
    });
  };

  get tabs_attributes() {
    const { t } = this.props;
    return {
      details: {
        title: t('Strap details'),
        attrs: [
          {
            id: 'sku',
            title: t('SKU'),
            value: (data) => data.sku
          },
          'bracelet_lugs',
          'bracelet_brac_color_label',
        ]
      },
      strap: {
        title: t('Material'),
        attrs: [
          'bracelet_brac_text_face',
          'bracelet_brac_text_back',
          'bracelet_brac_anim_top_label',
          'bracelet_colorisbracelet_label'
        ]
      },
      size: {
        title: t('Sizes'),
        attrs: [
          'bracelet_brac_brin6',
          'bracelet_brac_brin12'
        ]
      },
      buckle: {
        title: t('Buckle'),
        attrs: [
          'bracelet_picto_boucle_label',
          'bracelet_brac_buckle_mat_label',
          'bracelet_buckle_options_label',
          'bracelet_brac_buckle_color_label',
          'bracelet_brac_buckle_coating_label'
        ]
      }
    };
  }

  renderAttributes = (selectedTab, product) => (
    this.tabs_attributes[selectedTab].attrs.map((attr) => {
      const attribute = product.getAttribute(attr);
      if (attribute.value !== null) {
        return (
          <div className="tabs-item" key={typeof attr === 'string' ? attr : attr.id}>
            <span className="label">{attribute.label}</span>
            {' '}
            <span className="value">{attribute.value}</span>
          </div>
        );
      }
      return null;
    }));

  render() {
    const { selectedTab } = this.state;
    const { product } = this.props;
    const tabs = [];
    for (const tab in this.tabs_attributes) {
      const props = {
        onClick: () => this.setSelectedTab(tab)
      };
      if (tab === selectedTab) props.className = 'active';
      tabs.push(
        <button type="button" key={tab} {...props}>
          {this.tabs_attributes[tab].title}
        </button>
      );
    }

    return (
      <div className="tabs">
        <div className="tabs-menu">
          {tabs}
        </div>
        <div className="tabs-content">
          {this.renderAttributes(selectedTab, product)}
        </div>
      </div>
    )
  }
}

export default withTranslation()(StrapsTechnicalSpecs);
