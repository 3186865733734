import React from 'react';
import { useTranslation } from 'react-i18next';

function LimitedAvailability(props) {
  const { t } = useTranslation();
  
  if (!props.product.getLimitedAvailability()) {
    return null
  }
  return (
    <div className="limited-availability">
      <div className="badge limited-availability-badge">!</div>
      <div className="label">
        {t('Exclusive reference for some countries. Reach out to your local Tissot contact for more information.')}
      </div>
    </div>    
  );
}

export default LimitedAvailability;
