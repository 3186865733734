/* eslint-disable max-len */
import React from 'react';
import { withTranslation } from 'react-i18next';
import i18nWatcher from 'utils/i18nWatcher';
import AppContext from 'utils/AppContext';
import ReactGA from 'react-ga';
import Storage from 'utils/Storage';

class LanguageSwitcher extends React.Component {

  onChange = (e) => {
    this.props.i18n.changeLanguage(e.target.value);
    ReactGA.set({ dimension3: e.target.value });
    Storage.setItem('language', e.target.value);
    window.document.documentElement.lang = e.target.value;
    i18nWatcher.getAll().forEach(function(comp) {
      comp.setState({
        lang: e.target.value,
        isLoaded: false
      }, () => comp.load());
    });
  }

  render() {
    const { languages } = this.context
    const options = [];
    for (const lang in languages) {
      const props = {
        key: lang,
        value: lang,
      };
      options.push(<option {...props}>{languages[lang].name} ({lang})</option>);
    }

    return (
      <div className="switcher-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.4 17.4"><title>map</title><path d="M8.83,17.4H8.45A8.7,8.7,0,0,1,8.45,0H9A8.7,8.7,0,0,1,9,17.4Zm-.29-1.8h.31c.36,0,1-.9,1.43-2.53H7.12C7.56,14.7,8.18,15.56,8.54,15.6Zm3.58-2.53A11,11,0,0,1,11.5,15,6.81,6.81,0,0,0,14,13.07Zm-8.75,0A6.81,6.81,0,0,0,5.9,15a11,11,0,0,1-.62-1.94ZM1.86,9.6a6.84,6.84,0,0,0,.54,1.92A.91.91,0,0,1,3,11.27H5c-.07-.54-.11-1.1-.14-1.67Zm10.58,1.67H14.7a.87.87,0,0,1,.37.08,6.85,6.85,0,0,0,.47-1.75h-3C12.55,10.17,12.51,10.73,12.44,11.27Zm-5.67,0h3.86a16.31,16.31,0,0,0,.14-1.67H6.62C6.65,10.2,6.7,10.76,6.77,11.27Zm5.8-3.47h3a6.85,6.85,0,0,0-.47-1.75.87.87,0,0,1-.37.08H12.44C12.51,6.67,12.55,7.24,12.57,7.8Zm-5.95,0h4.15a16.31,16.31,0,0,0-.14-1.67H6.77C6.7,6.64,6.65,7.2,6.62,7.8Zm-4.76,0h3c0-.56.07-1.13.14-1.67H2.6a.87.87,0,0,1-.28,0A6.62,6.62,0,0,0,1.86,7.8ZM12.12,4.33H14A6.92,6.92,0,0,0,11.5,2.39,11,11,0,0,1,12.12,4.33Zm-5,0h3.16C9.83,2.7,9.21,1.85,8.85,1.8H8.54C8.18,1.85,7.56,2.7,7.12,4.33Zm-3.75,0H5.28A11,11,0,0,1,5.9,2.39,6.92,6.92,0,0,0,3.37,4.33Z" transform="translate(0 0)" fill="#575756"/></svg>
        <select className="language-switcher pimp-my-select" onChange={this.onChange} value={this.props.i18n.language}>
          {options}
        </select>
      </div>
    );
  }
}
LanguageSwitcher.contextType = AppContext;

export default withTranslation()(LanguageSwitcher);
