/* eslint-disable react/no-unused-state, react/no-danger */
import React from 'react';
import StrapsList from 'components/productlist/StrapsList';
import { withTranslation } from 'react-i18next';
import Watch from 'models/Watch';
import i18nWatcher from 'utils/i18nWatcher';
import i18n from 'i18next';
import AppContext from 'utils/AppContext';

class StrapsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      data: null,
      lang: i18n.language
    };
  }

  load() {
    const { store, token } = this.context;
    this.product = new Watch(null, store, token);
    this.product.sku = this.props.match.params.sku;
    this.product.lang = this.state.lang;
    this.product.fetch(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.sku !== prevProps.match.sku
    ) {
      this.setState({ isLoaded: false });
      this.load();
    }
  }

  componentDidMount() {
    i18nWatcher.add(this);
    this.load();
  }

  componentWillUnmount() {
    i18nWatcher.remove(this);
  }

  getErrorMsg() {
    const { t } = this.props;
    if (this.state.error === 404) {
      return t('this product is not currently available.');
    }
    else {
      return t('your request cannot be processed at this time');
    }
  }

  render() {
    return (
      <div className="container">
        <StrapsList
          sku={this.props.match.params.sku}
          watch={this.state.isLoaded ? this.product : null}
          isBackBtn={this.props.isBackBtn}
          filtersHash={this.props.filtersHash} />
      </div>
    )
  }
}
StrapsPage.contextType = AppContext;

export default withTranslation()(StrapsPage);
