/* eslint camelcase: "off" */
import ProductList from 'models/ProductList';

class ProductsGrouped extends ProductList {
  constructor(store, token) {
    super('api/{store}/product/vm/', store, token);
  }

}

export default ProductsGrouped;
