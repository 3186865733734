import Model from 'models/Model';

class Novelties extends Model {
  constructor(store, token) {
    super('api/{store}/novelty/');
    this.setGetParam('token', token);
    this.store = store;
  }

  set store(store) {
    if (this.params && 'store' in this.params) {
      this.params.store = store;
    }
    this._store = store;
  }

  get store() {
    return this._store;
  }
  
}

export default Novelties;
