import React from 'react';
import Spinner from 'components/Spinner';
import Categories from 'models/Categories';
import CollectionNavigation from 'components/nav/CollectionNavigation';
import i18nWatcher from 'utils/i18nWatcher';
import i18n from 'i18next';
import MessageBox from 'components/MessageBox';
import AppContext from 'utils/AppContext';
import { withTranslation } from 'react-i18next';

class Collections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      data: null,
      lang: i18n.language
    };
  }

  load(init) {
    const { store, token } = this.context;
    const cats = new Categories(store, token);
    cats.lang = this.state.lang;
    if (!init) this.props.selectedMenu(null)
    cats.fetch(this, categories => {
      categories._hierarchy.children[0].name = this.props.t('Current collections');
      this.setState({
        isLoaded: true,
        data: categories
      });
    });
  }

  componentDidMount() {
    i18nWatcher.add(this);
    this.load(true);
  }

  componentWillUnmount() {
    i18nWatcher.remove(this);
  }

  render() {
    const { isLoaded, data } = this.state;
    const { t } = this.props;
    if (!isLoaded) {
      return <Spinner color="white"/>;
    } else if (data._hierarchy.children.length < 1) {
      return <MessageBox type="error">{t('Unable to load collections')}</MessageBox>;
    } else {
      return (
        <CollectionNavigation
          model={data}
          selectedMenu={this.props.selectedMenu}
          close={this.props.close}
          style={this.props.style} />
      );
    }
  }
}
Collections.contextType = AppContext;

export default withTranslation()(Collections);
