/* eslint camelcase: "off" */
import ProductList from 'models/ProductList';

class NoveltiesGrouped extends ProductList {
  constructor(store, token) {
    super('api/{store}/product/novelties/', store, token);
  }

}

export default NoveltiesGrouped;
