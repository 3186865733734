import React from 'react';
import Spinner from 'components/Spinner';
import { Link } from 'react-router-dom'
import i18n from 'i18next';
import { withTranslation } from 'react-i18next'
import Novelties from 'models/Novelties';
import AppContext from 'utils/AppContext';
import month_utils from 'utils/month_utils';

class NoveltiesNavigation extends React.Component {
  constructor(props) {
    super(props);    
    this.state = {
      isLoaded: false,
      data: null,
    };
    this.onBack = this.onBack.bind(this);
  }
  
  componentDidMount() {   
    this.load(true);
  }

  onBack() {
    this.props.selectedMenu(null);
  }

  load(init) {
    const { store, token } = this.context;
    const novelties = new Novelties(store, token);
    novelties.lang = i18n.language;
    if (!init) this.props.selectedMenu(null)
    novelties.fetch(this);
  }
  
  nav() {
    this.props.selectedMenu('novelties');
  }
    
  render() {
    const { isLoaded, data } = this.state;
    const { t, close } = this.props;
        
    if (!isLoaded) {
      return <Spinner color="white"/>;
    } 

    if (this.props.status === null && data._data.items.length > 0) {
      return (
        <div className="nav-collection--item">
          <button
            type="button"
            onClick={() => this.nav()}>
            { t('Novelties') }
          </button>
        </div>
      );
    } else if (this.props.status === 'novelties') {
      return (
        <div className="nav-static">    
          <div className="nav-collection--item parent">
            <button
              type="button"
              onClick={this.onBack}>
              { t('Novelties') }
            </button>
          </div>
          <div className="nav-collection--item see-all">
            <Link to="/novelties/" onClick={close} >{ t('See all') }</Link>
          </div>
          { data._data.items.map(item => (
            <div key={item.launch_date} className="nav-collection--item">
              <Link to={'/novelties/' + item.launch_date} onClick={close}>{month_utils(new Date(item.launch_date), i18n.language)}</Link>
            </div>
          ))}
        </div>        
      );
    } 
    return null;    
  }
}

NoveltiesNavigation.contextType = AppContext;

export default withTranslation()(NoveltiesNavigation);
