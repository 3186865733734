import Model from 'models/Model';
import { getFamilyImage } from 'components/WatchGroup';

class Watch extends Model {
  constructor(data, store, token) {
    super('api/{store}/product/detail/{sku}/');
    this.setGetParam('token', token);
    this.store = store;
    this._sku = null;
    this.images = {
      detail1: null, // Détail 1
      detail2: null, // Détail 2
      detail3: null, // Détail 3
      base: null, // Soldat
      profil: null, // Profile
      b1: null, // Caseback
      zoom: null, // Zoom bracelet
      wrist: null, // Wrist
      box: null, // Ecrin
      mt: null, // Ancienne photo
      gl: null, // Ancienne photo
      shadowed: null, // Nouvelle photo avec ombre
      set: null // Montre et 2ème bracelet
    };
    if (data) {
      this.data = data;
      this._sku = data.sku;
    }
  }

  set store(store) {
    if (this.params && 'store' in this.params) {
      this.params.store = store;
    }
    this._store = store;
  }

  get store() {
    return this._store;
  }

  set sku(sku) {
    if (this.params && 'sku' in this.params) {
      this.params.sku = sku;
    }
    this._sku = sku;
  }

  get sku() {
    return this._sku;
  }

  get originalSku() {
    if ('attributes' in this._data) {
      return this._data.attributes.original_sku.value;
    }
    return null;
  }

  get name() {
    return this._data.name;
  }

  get menu() {
    return this._data.menu;
  }

  get price() {
    return this._data.price;
  }

  get soldier() {
    return this.getImageUrl('base', 'soldier');
  }

  get stock() {
    return this._data.stock_indicator;
  }

  get is_novelties() {
    return this._data.is_novelties;
  }

  get has_straps() {
    return this._data.has_straps;
  }

  get launch_date() {
    return this._data.launch_date;
  }

  get familyId() {
    if ('attributes' in this._data && 'filter_family' in this._data.attributes && this._data.attributes.filter_family) {
      return this._data.attributes.filter_family.value.id;
    }
    return 'no-family';
  }

  get video() {
    if ('video_url' in this._data.attributes && this._data.attributes.video_url) {
      return this._data.attributes.video_url.value;
    }
    return null;
  }

  get related() {
    return this._data.linked_product_items;
  }

  get pictos() {
    if ('attributes' in this._data && 'reference_picto_label' in this._data.attributes) {
      return this._data.attributes.reference_picto_label.value;
    }
    return []
  }

  get diameter() {
    if ('attributes' in this._data &&
      'reference_diameter3h9h' in this._data.attributes &&
      this._data.attributes.reference_diameter3h9h.value) {
      return Number(this._data.attributes.reference_diameter3h9h.value);
    }
    return null;
  }

  getFamilyFrontImage() {
    return getFamilyImage(this._data.image_start);
  }

  getFamilyBackImage() {
    return getFamilyImage(this._data.image_end);
  }

  set data(data) {
    data.attrs = {};
    this._data = data;
    this.parseImages();
  }

  getAttribute(name) {
    let value = null;
    let label = null;
    if (typeof name === 'string') {
      if (name in this._data.attributes) {
        const attr = this._data.attributes[name];
        label = attr.label;
        if (Array.isArray(attr.value)) {
          value = attr.value.map((val) => val.label).join(', ');
        } else if (typeof attr.value === 'string') {
          if (name === 'reference_is_chronometer') {
            value = attr.value === '0' ? null : '';
          } else {
            value = attr.value;
          }
        } else if ('label' in attr.value) {
          value = attr.value.label;
        }
      }
    }
    else {
      label = name.title;
      value = name.value(this._data);
    }
    return { value: value,
      label: label }
  }

  getFamilyId() {
    return this._data.sub_sub_family.id
  }

  getImageUrl(type, style) {
    if (!this.images[type] || this.images[type] === null) {
      return null;
    }
    const styles = {
      soldier: '?im=Resize=(400,400)',
      soldier_full: '?im=Resize=(700,700)'
    };
    const st = (style in styles) ? styles[style] : '';
    return process.env.REACT_APP_IMAGE_BASE_URL + this.images[type] + st;
  }

  getAllImages() {
    const images = [];
    let sizeTotal = 0;
    for (const type in this.images) {
      const size = (type === 'detail1' || type === 'detail2' || type === 'detail3') ? 2 : 1;
      if (this.images[type] !== null && type !== 'base' && type !== 'shadowed') {
        images.push({
          name: 'img-' + type,
          url: this.getImageUrl(type, 'soldier'),
          size: size
        });
        sizeTotal += size;
      }
    }
    return {
      size: sizeTotal,
      images: images
    };
  }

  getLimitedAvailability() {
    if ('limited_availability' in this._data) {
      return this._data.limited_availability
    }
    return false
  }

  isSampleToOrder() {
    if ('is_sample' in this._data) {
      return this._data.is_sample
    }
    return false
  }

  parseImages() {
    for (let i = 0; i < this._data.media_gallery_entries.length; i++) {
      if (this._data.media_gallery_entries[i].file.match(/zoombracelet/i)) {
        this.images.zoom = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].file.match(/PROFIL/i)) {
        this.images.profil = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].file.match(/B1/i)) {
        this.images.b1 = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].file.match(/_DETAIL\.1/i)) {
        this.images.detail1 = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].file.match(/_DETAIL\.2/i)) {
        this.images.detail2 = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].file.match(/_DETAIL\.3/i)) {
        this.images.detail3 = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].file.match(/_WRIST/i)) {
        this.images.wrist = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].file.match(/_BOX/i)) {
        this.images.box = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].file.match(/MT/i)) {
        this.images.mt = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].file.match(/GL/i)) {
        this.images.gl = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].file.match(/_R\.png/i)) {
        this.images.shadowed = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].file.match(/_SET/i)) {
        this.images.set = this._data.media_gallery_entries[i].file;
      }
      else if (this._data.media_gallery_entries[i].types.includes('main_without_shadow')) {
        this.images.base = this._data.media_gallery_entries[i].file;
      }
    }
  }
}

export default Watch;
